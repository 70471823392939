<template>
  <div class="page static-page">
    <SectionSelector
      v-for="(section, key) in sections"
      :data="section.data"
      :section="section.type"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :order-no="section.order_no"
      :key="key"
    />
    <OrganismMenuRight :phone-header="phoneNumberHeader" />
    <OrganismSidebarCampaign />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
import OrganismSidebarCampaign from "@/components/organisms/OrganismSidebarCampaign";
import OrganismMenuRight from "../../components/organisms/OrganismMenuRight";

export default {
  name: "StaticPage",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
      ],
    };
  },
  components: {
    OrganismMenuRight,
    OrganismSidebarCampaign,
    SectionSelector,
  },
  computed: {
    meta() {
      return this.$route.meta.page.meta;
    },
    sectionsData() {
      return this.$route.meta.page.sections;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    phoneNumberHeader() {
      if (!this.generalConfig.phoneNumberHeader) return "";
      return this.generalConfig.phoneNumberHeader;
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.sections = this.sectionsData.sort((a, b) => {
      if (a.order_no < b.order_no) {
        return -1;
      }
      if (a.order_no > b.order_no) {
        return 1;
      }
      return 0;
    });
  },
  beforeMount() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
        false
      );
    }
  },
};
</script>

<style lang="scss">
.footer-bright {
  position: fixed;
  bottom: 0;
  left: 0;
  font-weight: $regular;
  padding: 0.313rem 1.25rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: $white;
  background: $blackOpacity06;
  z-index: 999;
}
</style>
